import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../common/layouts/';
import Seo from '../common/seo';
import 'tachyons';


export default () => (
  <Layout>
    <Seo
      title="Disclaimers"
      description="Disclaimer notices and statements" />
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <div className="bg-lightest-blue pv5 flex flex-column items-center">
      <h1 className="db f1 display fw1">Disclaimers</h1>
      <p className="db sans-serif f5 tracked ttu tc">Effective date: November 12, 2024</p>
    </div>
    <div className="lh-copy f4 serif mw8 center">
      <p>NATURL Health ("us", "we", or "our") operates the naturlhealth.com website (the "Service").</p>
      <p>The information provided by NATURL Health is for general informational purposes only and reflects personal opinions and experiences. The owners and contributors to NATURL Health are not medical or scientific experts, nutritionists, dietitians, or licensed healthcare providers. By using the Service, you acknowledge that you have read, understood, and agree to this disclaimer in its entirety.</p>
      <h2 className="sans-serif">No Professional Advice</h2>
      <p>All content on NATURL Health, including but not limited to text, images, graphics, videos, and other materials, is for general informational and educational purposes only. This information is intended to share personal insights, thoughts, and opinions. <strong>It does not constitute, nor should it be considered, as medical, nutritional, scientific, or professional advice of any kind.</strong></p>
      <p>The content on the Service is not a substitute for professional health, medical, dietary, or lifestyle advice. If you have questions about your health, fitness, diet, lifestyle, or any medical condition, please consult a qualified healthcare provider, licensed nutritionist, or other certified professional before making any changes. Never disregard professional medical advice or delay in seeking it because of something you have read on NATURL Health.</p>
      <h2 className="sans-serif">No Warranties or Guarantees</h2>
      <p>NATURL Health makes no representations, warranties, or guarantees, express or implied, regarding the completeness, accuracy, reliability, suitability, or availability of the information provided. All content is presented on an "as is" basis, with no warranty of any kind, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>
      <p>We strive to keep information up to date and accurate; however, we do not make any commitments or assurances regarding the content’s accuracy, currency, or relevance. <strong>Use of any information from NATURL Health is at your own risk, and you should verify any information obtained from the Service with a certified professional before acting upon it.</strong></p>
      <h2 className="sans-serif">Assumption of Risk and Limitation of Liability</h2>
      <p>By accessing and using NATURL Health, you assume full responsibility for any actions you may take based on the information presented. You acknowledge and agree that NATURL Health, its owner(s), and contributors are not liable for any direct or indirect losses, damages, injuries, adverse health outcomes, or other claims that may arise from your use or reliance on information found on the Service. This includes, but is not limited to, any outcomes related to dietary changes, fitness practices, detox methods, or other lifestyle changes suggested or discussed on the Service.</p>
      <p>NATURL Health expressly disclaims liability for any decisions you make or actions you take as a result of the information provided on the Service. <strong>You acknowledge that following any information or suggestions on the Service is done at your own risk, and you agree to hold NATURL Health and its contributors harmless from any and all claims, damages, and liabilities.</strong></p>
      <h2 className="sans-serif">No Substitute for Professional Medical Services</h2>
      <p>NATURL Health does not provide medical, nutritional, or scientific services. The content on the Service should never be used as a substitute for professional health services, medical care, or health advice. Always consult qualified professionals for health-related decisions, treatment of medical conditions, and lifestyle or dietary changes.</p>
      <p><strong>If you experience any symptoms, health concerns, or suspect a medical condition, please seek immediate attention from a healthcare provider.</strong></p>
      <p><strong>In case of a medical emergency, call your doctor, emergency services, or go to the nearest emergency room immediately.</strong></p>
      <h2 className="sans-serif">Personal Responsibility and Informed Decision-Making</h2>
      <p>By using NATURL Health, you agree to take personal responsibility for any decisions or actions you may take based on the information provided. You agree to conduct your own research, seek professional advice, and make informed choices in line with your personal health needs and circumstances. NATURL Health does not assume responsibility for any personal health or lifestyle choices you make based on the content of the Service.</p>
      <p>The information provided on NATURL Health is not intended to diagnose, treat, cure, or prevent any disease or condition. Any health, wellness, or lifestyle suggestions are based on personal experience and opinion, not on professional or scientific qualifications.</p>
      <h2 className="sans-serif">No Guarantee of Results or Efficacy</h2>
      <p>NATURL Health does not guarantee or warrant specific results from following any information, recommendations, or lifestyle changes discussed on the Service. Each individual’s health, body, and circumstances are unique, and responses to dietary, fitness, or lifestyle practices will vary from person to person. <strong>We cannot and do not guarantee any particular outcome or improvement in health, fitness, or well-being.</strong></p>
      <p>All recommendations, suggestions, and statements on the Service are purely personal and should be evaluated in the context of each user’s unique needs and in consultation with qualified professionals.</p>
      <h2 className="sans-serif">Third-Party Links and Resources</h2>
      <p>NATURL Health may contain links to third-party websites, resources, or services. These links are provided solely for convenience and informational purposes and do not imply any endorsement, affiliation, or sponsorship by NATURL Health. We are not responsible for the content, accuracy, availability, policies, or practices of any third-party websites, and we do not assume liability for any consequences that may arise from your use of third-party links or resources found on the Service.</p>
      <p>We encourage users to review the terms, privacy policies, and disclaimers of any third-party websites they choose to visit.</p>
      <h2 className="sans-serif">Changes to This Disclaimer</h2>
      <p>NATURL Health reserves the right to modify or update this Disclaimer at any time, with or without notice. Changes to this Disclaimer will be reflected on this page, and your continued use of the website after any modifications indicates acceptance of the revised terms. We encourage you to review this Disclaimer periodically to stay informed of the terms governing your use of NATURL Health.</p>
      <hr/>
      <p>By accessing and using NATURL Health, you acknowledge that you have read, understood, and agree to the limitations, terms, and disclaimers outlined above. Thank you for respecting our commitment to transparency and responsible information sharing.</p>
    </div>
  </Layout>
)
